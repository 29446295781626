import React, { useState, useEffect } from 'react'
import { useQuery } from '@apollo/client'
import Layout from '../../components/Layout'
import Header from '../../components/Header'
import Headerbottomsec from '../../components/Headerbottomsec'
import Empezar from '../../components/Empezar'
import Categories from '../../components/Categories'
import Categoriescard from '../../components/Categoriescard'
import Footer from '../../components/Footer'
import { isMobile } from 'react-device-detect'

import { Query_categoryCollection, Query_homeCollection } from '../../service'
import { useFetchBenefitsDataAll } from '../../service/fetchBenifitsDataAll'

const IndexPage = (props) => {
  const { id } = props.params
  const [allData, setAllData] = useState({
    title: '',
    subtitle: '',
    banner: '',
    fisrtBlock: '',
    categoryCollection: [],
  })
  const { loading: loading1, data: homeCollection } =
    useQuery(Query_homeCollection)
  const { loading: loading2, data: categoryCollection } = useQuery(
    Query_categoryCollection
  )
  const [allBenefitsData, loading, error] = useFetchBenefitsDataAll({})
  useEffect(() => {
    if (categoryCollection && homeCollection) {
      const { title, subtitle, fisrtBlock, bannerMobile, bannerCollection } =
        homeCollection.homeCollection.items[0]
      setAllData({
        ...allData,
        categoryCollection: categoryCollection.categoryCollection.items,
        banner: isMobile ? bannerMobile.url : bannerCollection.items[0].url,
        fisrtBlock: fisrtBlock.json,
        title: title.json,
        subtitle: subtitle.json,
      })
    }
  }, [categoryCollection, homeCollection])

  if (loading && loading1 && loading2) return <div></div>
  if (error) return <div>error... {error.message}</div>

  return (
    <Layout>
      <Header />
      {/*<Headerbottomsec url={allData.banner} title={allData.title} subtitle={allData.subtitle} />
  <Empezar content={allData.fisrtBlock} />*/}
      <Categories
        categoriesData={allData.categoryCollection}
        isSlider={true}
        id={id}
      />
      <div
        className="bg-gray"
        style={{ paddingBottom: '40px', paddingTop: '20px' }}
      >
        <div style={{ width: '100%', padding: '0px 50px' }}>
          <div
            className="infopart text-center d-flex align-items-center"
            style={{ margin: '20px 0px' }}
          ></div>
          <div className="row d-flex justify-content-center categoriescard_wrap">
            {Object.keys(allBenefitsData).map((key) =>
              allBenefitsData[key].map(
                (innerItem) =>
                  innerItem.category.includes(id.toLowerCase()) && (
                    <Categoriescard item={innerItem} isCol={true} />
                  )
              )
            )}
          </div>
        </div>
      </div>
      <Footer isBGGray={true} />
    </Layout>
  )
}

export default IndexPage
