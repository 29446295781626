import React from "react";
import { navigate } from "gatsby";
import RichTextEditor from "./RichTextEditor";

function Categoriescard({ item, isCol = false }) {
  const { banner, title, shortText, __typename } = item;
  return (
    <div
      className={`${
        isCol ? "col-xxl-3 col-xl-5 col-lg-5 col-md-5 col-12 mb-4 ps-0 overflow-hidden" : ""
      } d-flex flex-col categoriescard-Continer`}
    >
      <div>
        <img src={banner.url} alt={"beneficio " + __typename} />
      </div>
      <div className="infopart" style={{ padding: "15px 0px 15px 15px" }}>
        <h5>{title}</h5>
        <RichTextEditor className="categoriescard-description" text={shortText.json} />
        <button
          className={"mt-4 btn infopart_btn btn_" + __typename}
          onClick={() => {
            navigate(`/detail/${__typename}/?utm_source=BeneficiosBancoGuayaquil_btn_${__typename}`);
          }}
        >
          Conoce más
        </button>
      </div>
    </div>
  );
}

export default Categoriescard;
