import React from "react";
import RichTextEditor from "./RichTextEditor";
import { isMobile } from "react-device-detect";

function Headerbottomsec({ url,title,subtitle}) {
  return (
    
    <div>
    {isMobile ?
      <div className="headerbottomsec_main banner_mobile" style={{
        backgroundImage: 'url('+url+')',
        backgroundSize: "100%",
        backgroundPosition: "top left",
        backgroundRepeat:"no-repeat",
        height: "33.999vh",
        color: "#f5f5f5"
      }}>
        <RichTextEditor className="col-lg-6 col-md-6 col-sm-12 banner-txt" text={subtitle} />
      </div>
    :
      <div className="headerbottomsec_main" style={{
        backgroundImage: 'url('+url+')',
        backgroundSize: "100%",
        backgroundPosition: "top left",
        backgroundRepeat:"no-repeat",
        height: "33.999vh",
        color: "#f5f5f5"
      }}>
        <RichTextEditor className="col-lg-6 col-md-6 col-sm-12 banner-txt" text={subtitle} />
      </div>
    }
    
    </div>
  )}

export default Headerbottomsec;
