import React from 'react'
import { navigate } from 'gatsby'
import Carousel from '../components/Carousel'

const settings = {
  dots: false,
  speed: 500,
  slidesToShow: 7,
  slidesToScroll: 1,
  responsive: [
    {
      breakpoint: 1221,
      settings: {
        slidesToShow: 7,
      },
    },
    {
      breakpoint: 1220,
      settings: {
        slidesToShow: 5,
        infinite: true,
      },
    },
    {
      breakpoint: 789,
      settings: {
        slidesToShow: 3,
        infinite: true,
      },
    },
  ],
}

function Categories({ categoriesData, isSlider = false, id }) {
  const RanderCategories = ({ item }) => {
    return (
      <li key={item.id}>
        <div className="categories_img cursor-pointer ">
          <div
            className={`categories_design ${
              isSlider && id === item?.name ? 'isActive-categories_design' : ''
            }`}
            // style={isSlider && id === item?.name ? { height: '175px' } : {}}
          ></div>
          <img
            src={item?.icon?.url}
            onClick={() => navigate(`/category/${item?.name}/?utm_source=BeneficiosBancoGuayaquil_category_${item?.name}`)}
            alt={item?.name}
            className={`viajesimg ${
              isSlider && id === item?.name ? 'isActive-viajesimg' : ''
            }`}
            // style={isSlider && id === item?.name ? { minWidth: '185px' } : {}}
          />
        </div>
        <p style={{ fontFamily: 'bentonsans_boldregular' }}>{item?.name}</p>
      </li>
    )
  }

  return (
    <div className="categories_wrap">
      <div className="container">
        <div className="row">
          <div className="col-md-12 categories_main">
            <h5
              style={{
                fontWeight: 'bolder',
                fontFamily: 'bentonsans_mediumregular',
              }}
            >
              CATEGORÍAS
            </h5>
            <ul style={{ display: isSlider ? 'contents' : 'flex' }}>
              {isSlider ? (
                <Carousel settings={settings}>
                  {categoriesData.map((item) => (
                    <RanderCategories item={item} />
                  ))}
                </Carousel>
              ) : (
                categoriesData.map((item) => <RanderCategories item={item} />)
              )}
            </ul>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Categories
